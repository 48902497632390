import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/App/Layout";
import Content, { HTMLContent } from "../components/Blog/Content";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import PreviewCompatibleImage from "../components/Blog/PreviewCompatibleImage";

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  date,
  featuredimage,
  showAuthor,
  authorImage,
  author,
  authorDescription,
  authorLink,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="section blog-section">
      {helmet || ""}

      <PreviewCompatibleImage
        imageInfo={{
          image: featuredimage,
          alt: `featured image thumbnail for post ${title}`,
          width: featuredimage?.childImageSharp?.gatsbyImageData?.width,
          height: featuredimage?.childImageSharp?.gatsbyImageData?.height,
        }}
        imageStyle={{
          maxHeight: 550,
        }}
      />
      <div className="container content">
        <div className="columns">
          <div className="button-link">
            <Link className="button text-3 common-text" to="/blog" component="a">
              Back to all articles
            </Link>
          </div>
          <div id="content-section" className="column is-10 is-offset-1">
            <p className="date text-3">{date}</p>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content} className='text-3' />
            <div className="signature">
              {showAuthor && <div className="author">
                <div className="author-logo">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: authorImage,
                      alt: `author image thumbnail for post ${title}`,
                      width: 98,
                      height: 98,
                    }}
                  />
                </div>
                <div className="author-desc">
                  <div className="author-name">
                    {authorLink.trim() ? <a href={authorLink} target="_blank" rel="noreferrer">{author}</a> : author}
                  </div>
                  <div className="author-short">{authorDescription}</div>
                </div>
              </div>}

              <hr />

              {tags && tags.length ? (
                <div className="tag-container">
                  <h4 className="font-weight-light tag-label">Tags</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`} className="tag">{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
  featuredimage: PropTypes.object,
  showAuthor: PropTypes.bool,
  authorImage: PropTypes.object,
  author: PropTypes.string,
  authorDescription: PropTypes.string,
  authorLink: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Navbar />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.metaTitle.trim() || post.frontmatter.title}`}</title>
            <meta
              content={`${post.frontmatter.metaDescription.trim()}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        featuredimage={post.frontmatter.featuredimage}
        showAuthor={post.frontmatter.showAuthor}
        authorImage={post.frontmatter.authorImage}
        author={post.frontmatter.author}
        authorDescription={post.frontmatter.authorDescription}
        authorLink={post.frontmatter.authorLink}
      />
      <Footer />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              transformOptions: {
                fit: COVER
              }
            )
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        metaTitle
        metaDescription
        tags
        showAuthor
        authorImage {
          childImageSharp {
            gatsbyImageData(
              width: 200
              height: 200
            )
          }
        }
        author
        authorDescription
        authorLink
      }
    }
  }
`;
